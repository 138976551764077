<template>
  <div class="personal wrap">
  </div>
</template>

<script>
export default {
  name: 'Personal',
};
</script>

<style lang="sass">
  .personal
    background-size: contain
    background-position: center
    background-origin: content-box
</style>
